import React, {useEffect} from 'react';
import './payment-result.scss'
import {useNavigate} from "react-router-dom";
import {backButtonClickedOff, backButtonClickedOn, onOpenLink, onShowBackBtn} from "../../utils/telegram-events";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";
import {userColors} from "../../constants/user-colors";
import {IPayment} from "../../interfaces/payment/payment";
import {operatorsSelector} from "../../store/slices/operator-slice";
import {message, Row} from "antd";
import {paymentFieldSelector, resetResult, setResult} from "../../store/slices/payment-form-slice";
import Loader from "../../shared/loader/loader";
import {setFields, setFilled} from "../../store/slices/contact-form-slice";
import {formatDate} from "../../utils/dateFormatter";
import {findImage, findInputImage} from "../../utils/findImage";
import Actions from "./components/actions/actions";
import {getPaymentApi} from "../../api/payments/payments";
import {errorHandler} from "../../utils/error-handler";
import {prodUrl} from "../../constants/receipt-url";

const utc = require('dayjs/plugin/utc')
const dayjs: any = require('dayjs')
dayjs.extend(utc)

interface Props {

}

const PaymentResult: React.FC<Props> = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {theme} = useSelector(themeSelector)
    const {operators} = useSelector(operatorsSelector)
    const {paymentFields} = useSelector(paymentFieldSelector)
    const dispatch = useDispatch()



    const payment: IPayment | undefined = paymentFields.result

    const month = t(`months.${dayjs(payment?.created_at).utc().format('MMM')}`)

    
    const onOpenReceiptHandler = () => {
        if (payment?.gnk_qr_link) {
            onOpenLink(payment.gnk_qr_link)
            return
        }
        if(!payment?.gnk_qr_link && payment?.transaction_id) {
            onOpenLink(`${prodUrl}?id=${payment?.transaction_id}`)
            return
        }
        message.error(t('transactionNotFound'))
    }


    const getPaymentHandler = () => {
        if (payment) {
            getPaymentApi(payment.id.toString())
                .then((response) => {
                    if (response?.data?.result){
                        dispatch(setResult(response.data.result))
                    }
                }).catch((e) => {
                    errorHandler(e)
                    navigate('/')
            })
        }
    }


    useEffect(() => {
        if (!payment) {
            navigate('/')
        } else {
            const onRedirect = () => {
                resetResultHandler()
                navigate(-1)
            }

            backButtonClickedOn(onRedirect)
            onShowBackBtn()
            return () => {
                backButtonClickedOff(onRedirect)
            }
        }
    }, [payment])


    useEffect(() => {
        getPaymentHandler()

    }, [])


    if (!payment) return <Loader/>

    if (payment && !payment.transaction_id) return <Loader/>

    const users_contact = payment.users_contact

    const foundOperator = operators.entity.find((item) => item.id === payment.operator_id)

    const resetResultHandler = () => dispatch(resetResult())
    const onAddContact = () => {
        dispatch(setFields({
            phone: payment.phone_number,
            name: '',
            icon: findImage(foundOperator, theme.entity),
            operator: foundOperator,
            id: undefined
        }))
        dispatch(setFilled(true))
        resetResultHandler()
        navigate('/contacts')
    }

    return (
        <div className={'payment-result'}>
            <div className={'payment-result__data'}>
                <div className={'payment-result__icon'}>
                    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3"
                              d="M42.0002 83.6667C65.012 83.6667 83.6668 65.0119 83.6668 42C83.6668 18.9882 65.012 0.333374 42.0002 0.333374C18.9883 0.333374 0.333496 18.9882 0.333496 42C0.333496 65.0119 18.9883 83.6667 42.0002 83.6667Z"
                              fill="#71E13C"/>
                        <path
                            d="M61.8707 24.5737C63.4408 22.8914 66.0774 22.8005 67.7597 24.3706C69.442 25.9408 69.533 28.5774 67.9628 30.2597L38.7961 61.5097C37.2728 63.1419 34.7336 63.2832 33.0384 61.8302L18.4551 49.3302C16.7079 47.8327 16.5056 45.2022 18.0032 43.4551C19.5008 41.7079 22.1312 41.5055 23.8784 43.0031L35.4292 52.9038L61.8707 24.5737Z"
                            fill="#71E13C"/>
                    </svg>
                </div>
                <h2 className={`payment-result__title ${theme.entity === 'dark' ? 'payment-result__title--dark' : ''}`}>
                    {t('success')}
                </h2>
                <div
                    className={`payment-result__contact ${theme.entity === 'dark' ? 'payment-result__contact--dark' : ''}`}>
                    <div style={users_contact ? {
                        background: users_contact.image,
                        color: userColors.find(({backgroundColor}) => users_contact.image === backgroundColor)?.color
                    } : undefined}
                         className={`${users_contact ? "transaction__contact" : "transaction__icon"}   ${theme.entity === 'dark' ? 'transaction__icon--dark' : ""}`}>
                        {!users_contact ? <img
                            src={findInputImage(foundOperator, theme.entity)}
                            alt={""}/> : users_contact.name[0].toUpperCase()}
                    </div>
                    <div
                        className={`transaction__info payment-result__info ${theme.entity === 'dark' ? 'transaction__info--dark' : ""}`}>
                        <Row justify={'space-between'}>
                            <div className={'transaction__numbers'}>
                                <p className={`transaction__phone ${theme.entity === 'dark' ? 'transaction__phone--dark' : ''}`}>
                                    {users_contact ? users_contact.name : payment?.phone_number}
                                </p>
                                <p className={`transaction__date ${theme.entity === 'dark' ? "transaction__date--dark" : ""} `}>
                                    {formatDate(payment.created_at, month)}
                                </p>
                            </div>
                            <div className={'transaction__sum'}>
                                {new Intl.NumberFormat('ru-RU').format(payment.amount)} {t('sum').toLowerCase()}
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
            <div onClick={onOpenReceiptHandler} className={'payment__receipt'}>
                <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.28 0H4.72C0.94 0 0 1.01 0 5.04V16.3C0 18.96 1.46 19.59 3.23 17.69L3.24 17.68C4.06 16.81 5.31 16.88 6.02 17.83L7.03 19.18C7.84 20.25 9.15 20.25 9.96 19.18L10.97 17.83C11.69 16.87 12.94 16.8 13.76 17.68C15.54 19.58 16.99 18.95 16.99 16.29V5.04C17 1.01 16.06 0 12.28 0ZM11.5 9.75H5.5C5.09 9.75 4.75 9.41 4.75 9C4.75 8.59 5.09 8.25 5.5 8.25H11.5C11.91 8.25 12.25 8.59 12.25 9C12.25 9.41 11.91 9.75 11.5 9.75ZM12.5 5.75H4.5C4.09 5.75 3.75 5.41 3.75 5C3.75 4.59 4.09 4.25 4.5 4.25H12.5C12.91 4.25 13.25 4.59 13.25 5C13.25 5.41 12.91 5.75 12.5 5.75Z"
                        fill="#50A7EA"/>
                </svg>
                <p className={'payment__receipt--text'}>
                    {payment?.gnk_qr_link ? t('fiscalReceipt') : t('invoice')}
                </p>
            </div>
            <Actions users_contact={users_contact} resetResultHandler={resetResultHandler} onAddContact={onAddContact}/>
        </div>
    );
};

export default PaymentResult;