import React from 'react'
import './payment-item.scss'
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {IPayment} from "../../../../../../interfaces/payment/payment";
import {formatDate} from "../../../../../../utils/dateFormatter";
import {useTranslation} from "react-i18next";
import {onOpenLink} from "../../../../../../utils/telegram-events";
import {prodUrl} from "../../../../../../constants/receipt-url";

const utc = require('dayjs/plugin/utc')
const dayjs: any = require('dayjs')
dayjs.extend(utc)

interface Props {
    payment: IPayment
}

const PaymentItem: React.FC<Props> = ({
                                          payment
                                      }) => {
    const {theme} = useSelector(themeSelector)
    const {created_at, amount} = payment
    const {t} = useTranslation()

    const onOpenLinkHandler = () => {
        if (payment?.gnk_qr_link) {
            onOpenLink(payment.gnk_qr_link)
            return
        }
        if (!payment?.gnk_qr_link && payment?.transaction_id) {
            onOpenLink(`${prodUrl}?id=${payment?.transaction_id}`)
            return
        }
    }

    const month = t(`months.${dayjs(created_at).utc().format('MMM')}`)


    return (
        <div className={'payment__item'}>
            <p className={`transaction__date payment__date ${theme.entity === 'dark' ? "payment__date--dark" : ""} `}>
                {formatDate(created_at, month)}
            </p>
            <div className={'payment__item-info'}>
                <div className={'transaction__sum'}>
                    {new Intl.NumberFormat('ru-RU').format(amount)} {t('sum').toLowerCase()}
                </div>
                <button onClick={onOpenLinkHandler}
                        className={`payment__item--check ${theme.entity === 'dark' ? 'payment__item--check-dark' : ''}`}>
                    {t('receipt')}
                </button>
            </div>

        </div>
    )
}

export default PaymentItem
